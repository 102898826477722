html {
    font-size: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
        sans-serif;
    font-size: 1rem;
    line-height: 1.5;
    color: #000426;
}

button:disabled {
    opacity: 0.5;
}

ul {
    padding: 0 20px;
}

.app {
    &__container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        overflow: auto;
        max-height: 700px;
        width: 610px;

        &--mobile {
            width: 100%;
            height: 100%;
        }

        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: #ccc;
            border-radius: 0px;
        }
        &::-webkit-scrollbar-thumb:hover {
            background: #b3afb3;
        }
        &::-webkit-scrollbar-track {
            background: #f0f0f0;
            border-radius: 0px;
            box-shadow: inset 0px 0px 0px 0px #f0f0f0;
        }
    }

    &__web-container {
        background-color: #fff;
    }

    &__mobile-container {
        background-color: #fff;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
    }
}

.react-tel-input {
    display: flex;
    justify-content: flex-end;

    & .flag-dropdown {
        left: 0;
        width: 12%;

        & .selected-flag {
            border-radius: 12px;
        }
    }
}

.country-code {
    &__input {
        padding: 12px;
        padding-left: 12px !important;
        width: 85% !important;
        border: 1px solid transparent !important;
        border-radius: 10px !important;
        outline: none !important;
        background-color: #ebf0eb !important;
        height: 38px !important;
    }
    &__button {
        border: 1px solid transparent !important;
        border-radius: 10px !important;
        outline: none !important;
        background-color: #ebf0eb !important;

        .selected-flag {
            background: #ebf0eb !important;

            &:hover {
                background-color: #ebf0eb !important;
            }
        }
    }
}
